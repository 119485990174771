import React from 'react';

import { cardTransition } from '../Card';

import { CardFooter, CardHeader, footerAnimations, headerAnimations } from './CardContent.style';
import Code from './code/Code';
import Logos from './logos/Logos';
import Price from './price/Price';
import Slider from './slider/Slider';

export default ({ animate, brand, cardTitle }) => {
  const sliderCopy = 'Send to Sam Lee';
  return (
    <div>
      <CardHeader animate={animate} variants={headerAnimations} transition={cardTransition}>
        <Logos src={brand.logo} scale={brand.logoScale} />
        <Price currency={brand.currency} amount={brand.amount} id={brand.id} />
      </CardHeader>

      <CardFooter animate={animate} variants={footerAnimations} transition={cardTransition}>
        {!animate.match('slider|done') && <h2>{cardTitle}</h2>}
        {animate.match('code') && <Code brand={brand} />}
        {animate.match('slider|done') && <Slider animate={animate} copy={sliderCopy} />}
      </CardFooter>
    </div>
  );
};
