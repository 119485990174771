import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';

export const LogoWrapper = styled.div`
  &:nth-child(2) {
    margin-top: 19px;
  }
  ${media.md} {
    margin: 0 33px 0 0;
  }
  .gatsby-image-wrapper {
    ${media.md} {
      width: 60px !important;
      img {
        object-fit: contain !important;
      }
    }
    ${media.sm} {
      width: 47px !important;
    }
  }
`;

export const Wrapper = styled.div`
  background-color: #fbfbfc;
  padding-bottom: 85px;
  padding-top: 81px;
  ${media.md} {
    padding-top: 16px;
    padding-bottom: 0;
  }
  ${media.xs} {
    padding-top: 28px;
    padding-bottom: 16px;
  }
`;
export const Container = styled(SharedContainer)``;

export const SliderWrapper = styled.div`
  display: none;
  overflow: hidden;
  ${media.xs} {
    display: block;
  }
  ${LogoWrapper} {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 60px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 71px;
  ${media.sm} {
    justify-content: center;
  }
  ${media.xs} {
    display: none;
  }
`;
