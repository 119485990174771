import { useState, useEffect } from 'react';

export default () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  const [coords, setCoords] = useState([0, 0]);
  const [screenCoords, setScreenCoords] = useState([0, 0]);
  const [angle, setAngle] = useState();

  if (typeof window === 'undefined') {
    return {
      angle: 0,
      screenCoords: [],
    };
  }

  useEffect(() => {
    const onResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    const onMouseMove = (e) => {
      setCoords([e.clientX, e.clientY]);
    };
    window.addEventListener('mousemove', onMouseMove);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  // useEffect(() => {
  //   const handleOrientation = (event: DeviceOrientationEvent) => {
  //     console.log(event)
  //     if (event && event.beta && event.gamma) {
  //       setCoords([(event.beta / 180 / 2 + 0.5) * size[0], (event.gamma / 90 / 2 + 0.5) * size[1]])
  //     }
  //   }
  //   window.addEventListener("deviceorientation", handleOrientation)
  //   return () => {
  //     window.removeEventListener("deviceorientation", handleOrientation)
  //   }
  // }, [size])

  useEffect(() => {
    setScreenCoords([(coords[0] * 2) / size[0] - 1, (coords[1] * 2) / size[1] - 1]);
  }, [size, coords]);

  useEffect(() => {
    const a = Math.atan2(coords[1] - size[1] / 2, coords[0] - size[0] / 2);
    setAngle(a);
  }, [coords, size]);

  return { angle, screenCoords };
};
