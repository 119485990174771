import React, { useState, useEffect } from 'react';

import { getCookie, setCookie } from 'utils';

import { Wrapper, Text, StyledLink, CloseButton, StyledCloseIcon } from './banner.styles';

export const Banner = ({ id, text, linkUrl, linkText }) => {
  const [isVisible, setIsVisible] = useState(false);

  const cookiesName = 'banners';

  const handleButtonClose = () => {
    setIsVisible(false);
    setCookie(cookiesName, id);
  };

  useEffect(() => {
    if (!(getCookie(cookiesName) && getCookie(cookiesName).includes(id))) {
      setIsVisible(true);
    }
  }, []);

  return (
    <Wrapper visible={isVisible}>
      <Text>
        {text} {linkUrl && linkText && <StyledLink to={linkUrl}>{linkText}</StyledLink>}
      </Text>
      <CloseButton onClick={handleButtonClose}>
        <StyledCloseIcon />
      </CloseButton>
    </Wrapper>
  );
};
