import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import Card3d from '../Card3d';

import { GradientPlane } from './Gradient';

const grandientAnimationVariants = {
  hidden: { width: 320, height: 210, opacity: 0 },
  card: { width: 320, height: 210, opacity: 0 },
  logos: {
    width: 420,
    height: 210,
    opacity: 0.33,
  },
  code: { width: 420, height: 320, opacity: 0.33 },
  slider: { width: 420, height: 210, opacity: 0.33 },
  done: { width: 420, height: 210, opacity: 0.33 },
};

const gradientAnimationTransition = { type: 'spring', stiffness: 50, damping: 100, delay: 0.15 };

export default ({ animate, coords }) => {
  const hidden = animate.match('hidden');

  return (
    <Card3d coords={coords} factor={hidden ? 1 : 0.1} z={-50} fadeIn={hidden ? 0 : 1}>
      <Card
        animate={animate}
        variants={grandientAnimationVariants}
        transition={gradientAnimationTransition}
        style={{
          borderRadius: 10,
          transform: `translate(-50%,-30%)`,
          overflow: 'hidden',
        }}
      >
        <GradientPlane opacity={1} />
      </Card>
    </Card3d>
  );
};

const Card = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  transform-style: preserve-3d;
  border-radius: 10px;
`;
