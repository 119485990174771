import { Link } from 'gatsby';
import * as React from 'react';

import LogoDescriptionSvgUrl from 'assets/images/pages/home/drive/vodafone-dark.url.svg';
import FooterSvgUrl from 'assets/images/pages/home/drive/wegift-blue.url.svg';
import { Button } from 'components/shared/button';
import { getTextWithoutParagraph } from 'utils';
import { useParallax } from 'utils/use-parallax';

import {
  Wrapper,
  Bubble,
  Container,
  ContainerFooter,
  Grid,
  ContentLeft,
  ContentLeftWrapper,
  ContentRight,
  StyledTitle,
  DescriptionWrapper,
  Description,
  Image,
  ImageStatic,
  ContentFooter,
  FooterLeft,
  FooterText,
  FooterTitle,
  FooterDescription,
  ButtonWrapper,
} from './partner.styles';

export const PartnerView = (props) => {
  const {
    quote,
    caption,
    bannerTitle,
    bannerDescription,
    bannerButtonText,
    bannerButtonLink,
    imagePerson,
  } = props;

  const { ref, offset } = useParallax();

  return (
    <Wrapper ref={ref} style={{ '--offset': offset }}>
      <Container>
        <Grid
          columns={2}
          gap={0}
          media={{
            md: { columns: 1 },
          }}
        >
          <ContentLeft>
            <ContentLeftWrapper>
              <StyledTitle
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: getTextWithoutParagraph(quote),
                }}
              />
              <DescriptionWrapper>
                <ImageStatic src={LogoDescriptionSvgUrl} alt="Vodafone" width={149} height={36} />
                <Description>{caption}</Description>
              </DescriptionWrapper>
            </ContentLeftWrapper>
          </ContentLeft>
          <ContentRight>
            <Bubble />
            <Image fixed={imagePerson} />
          </ContentRight>
        </Grid>
      </Container>
      <ContainerFooter>
        <ContentFooter>
          <FooterLeft>
            <ImageStatic src={FooterSvgUrl} alt="WeGift" width={80} height={80} />
            <FooterText>
              <FooterTitle as="h3">{bannerTitle}</FooterTitle>
              <FooterDescription>{bannerDescription}</FooterDescription>
            </FooterText>
          </FooterLeft>
          <ButtonWrapper>
            <Button component={Link} theme="fill" to={bannerButtonLink.url}>
              {bannerButtonText}
            </Button>
          </ButtonWrapper>
        </ContentFooter>
      </ContainerFooter>
    </Wrapper>
  );
};
