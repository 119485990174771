import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import { media, color, fontSize, transition } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';
import { Title } from 'components/shared/title';

import { ArrowRightIcon } from './icons';

export const Wrapper = styled.section`
  padding-top: 80px;
  padding-bottom: 146px;
  background-color: #fbfbfc;

  ${media.md} {
    margin-bottom: 0;
  }

  ${media.xs} {
    padding-top: 60px;
  }
`;

export const Container = styled(SharedContainer)``;

export const Grid = styled(SharedGrid)`
  margin: 0 20px;
  border-radius: 16px;
  background-color: ${color.text.tertiary};
  box-shadow: 0 75px 80px -45px rgba(50, 50, 93, 0.5);

  ${media.md} {
    margin: 0;
  }
`;

export const ImageStatic = styled.img``;

export const StyledTitle = styled(Title)`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: ${color.text.secondary};
  text-transform: uppercase;
  margin: 0;
  ${media.sm} {
    font-size: 12px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  ${media.md} {
    margin-bottom: 35px;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 509px;
  padding: 48px 56px 0;
  ${media.lg} {
    padding: 48px 40px 0;
  }
  ${media.md} {
    min-height: 100%;
    padding: 50px 35px 0;
  }
  ${media.sm} {
    padding: 16px 35px 0;
  }
  ${(p) => css`
    ${p.borderRight &&
    css`
      box-shadow: inset -1px 0 0 0 #d9dfe4;
      ${media.md} {
        box-shadow: inset 0 0 1px 0 #d9dfe4;
      }
    `}
  `}
`;

export const ItemContent = styled.div``;

export const ItemHead = styled.div`
  ${media.md} {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  ${media.sm} {
    margin-bottom: 8px;
  }
`;

export const ItemImg = styled.div`
  margin-bottom: 23px;
  ${media.md} {
    margin-right: 21px;
    margin-bottom: 0;
  }
  svg {
    ${media.sm} {
      width: 55px;
    }
  }
`;

export const ItemTitle = styled(Title)`
  font-size: ${fontSize.lg};
  line-height: 1.5;
  margin-bottom: 23px;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 0;
  }
`;

export const ItemDescription = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: #838791;
  margin: 0 0 47px;

  ${media.md} {
    margin: 0 0 32px;
  }

  br {
    ${media.md} {
      display: none;
    }
  }

  a {
    color: ${color.primary};
    text-decoration: none;
  }
`;

export const SellGiftCardsLinkArrowIcon = styled(ArrowRightIcon)`
  margin-top: 3px;

  g > g {
    opacity: 0.5;
    transition: ${transition.base};
    ${(p) => css`
      ${p.theme === 'blue' &&
      css`
        stroke: #00c1de;
      `}
    `}
    ${(p) => css`
      ${p.theme === 'purple' &&
      css`
        stroke: #798fd6;
      `}
    `}
    ${(p) => css`
      ${p.theme === 'purple-dark' &&
      css`
        stroke: #a76af5;
      `}
    `}
    transition: ${transition.base};
  }
`;

export const SellGiftCardsLinkText = styled.span`
  margin-right: 20px;
  ${(p) => css`
    ${p.color === 'blue' &&
    css`
      color: #00c1de;
    `}
  `}
  ${(p) => css`
    ${p.color === 'purple' &&
    css`
      color: #798fd6;
    `}
  `}
${(p) => css`
    ${p.color === 'purple-dark' &&
    css`
      color: #a76af5;
    `}
  `}
`;

export const SellGiftCardsLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: #00c1de;
  background-color: transparent;
  border: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-bottom: 40px;
  cursor: pointer;
  outline: none;
  transition: ${transition.base};
  ${media.md} {
    margin-bottom: 55px;
  }
  ${media.sm} {
    margin-bottom: 42px;
  }
  ${SellGiftCardsLinkArrowIcon} {
    transition: ${transition.base};
  }
  &:hover {
    ${SellGiftCardsLinkArrowIcon} {
      transition: ${transition.expo};
      transform: translateX(3px);
      g > g {
        opacity: 1;
        ${(p) => css`
          ${p.color === 'blue' &&
          css`
            color: #00c1de;
          `}
        `}
        ${(p) => css`
          ${p.color === 'purple' &&
          css`
            color: #798fd6;
          `}
        `}
        ${(p) => css`
          ${p.color === 'purple-dark' &&
          css`
            color: #a76af5;
          `}
        `}
      }
    }
  }
`;
