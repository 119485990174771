import { motion, AnimatePresence } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const width = 220;

const iconAnim = {
  normal: {
    x: 0,
    transition: {
      ease: 'easeOut',
      duration: 0.1,
    },
  },
  done: {
    x: width,
    transition: {
      ease: 'easeIn',
      duration: 0.2,
    },
  },
};

const copyAnim = {
  start: {
    y: -10,
    opacity: 0,
  },
  normal: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'easeIn',
      duration: 0.2,
    },
  },
  exit: {
    y: 10,
    opacity: 0,
    transition: {
      type: 'tween',
      ease: 'easeIn',
      duration: 0.06,
    },
  },
};

export default (props) => {
  const animate = props.animate.match(/done/) ? 'done' : 'normal';
  const copy = animate !== 'done' ? props.copy : 'Customer acquired';
  return (
    <div>
      <Slider
        color={animate.match('normal') ? '#8787a4' : '#00C1DE'}
        background={
          animate.match('normal')
            ? 'linear-gradient(90deg, #ddd, #eee)'
            : 'linear-gradient(90deg, #00c1de20, #eee)'
        }
      >
        <Content>
          <Icon animate={animate} variants={iconAnim}>
            <ParticleContainer size={0} offset={animate.match('normal') ? 0 : 1}>
              {Array(5)
                .fill(0)
                .map((_value, i, a) => (
                  <Particle key={`a${i}`} angle={Math.PI * 2 * (i / a.length)} />
                ))}
            </ParticleContainer>
            <ParticleContainer size={1} offset={animate.match('normal') ? 0 : 1}>
              {Array(7)
                .fill(0)
                .map((_value, i, a) => (
                  <Particle key={`b${i}`} angle={Math.PI * 2 * (i / a.length)} />
                ))}
            </ParticleContainer>
          </Icon>
          <AnimatePresence exitBeforeEnter>
            <Copy initial="start" animate="normal" exit="exit" variants={copyAnim} key={copy}>
              {copy}
            </Copy>
          </AnimatePresence>
        </Content>
      </Slider>
    </div>
  );
};
const Slider = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  background: ${(props) => props.background};
  border-radius: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 14px;
  overflow: hidden;
  color: ${(props) => props.color};
  transition: all 220ms ease 120ms;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row;

  font-weight: 500;
  line-height: 1.44em;
`;

const Copy = styled(motion.div)`
  position: relative;
  margin-left: 42px;
`;

const Icon = styled(motion.div)`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: currentColor;

  &:after {
    z-index: 2;
    content: ' ';
    opacity: 0.5;
    position: absolute;
    left: 8px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: scale(0.8) rotate(45deg);
  }
`;

const ParticleContainer = styled.div`
  position: relative;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  --offset: ${({ offset }) => offset};
  --size: ${({ size }) => size};
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%) rotate(calc(var(--offset) * calc(66deg + var(--size) * 300deg)));
`;

const Particle = styled.div`
  content: '';
  transition: all calc(var(--offset) * 1400ms - var(--size) * 500ms) ease-out,
    background-color 0ms linear 0ms;
  --angle: ${({ angle }) => angle.toFixed(2)}rad;

  position: absolute;
  width: calc(var(--size) * 3px + 4px);
  height: calc(var(--size) * 3px + 4px);
  border-radius: 4px;
  background-color: currentColor;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%) rotate(calc(var(--angle)))
    translateX(calc(4px + 14px * var(--offset) + (1 - var(--size)) * 5px))
    scale(calc(1 - var(--offset)));
`;
