import * as React from 'react';

import SolutionImg2 from 'assets/images/pages/home/solutions/enterprises.url.svg';
import SolutionImg3 from 'assets/images/pages/home/solutions/retail-brands.url.svg';
import SolutionImg1 from 'assets/images/pages/home/solutions/service-providers.url.svg';
import { GridCell } from 'components/shared/grid';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Container,
  ContentWrapper,
  ContentHead,
  Grid,
  StyledTitle,
  SellGiftCardsLink,
  SellGiftCardsLinkText,
  SellGiftCardsLinkArrowIcon,
  ItemWrapper,
  ItemContent,
  ItemHead,
  ItemImg,
  ImageStatic,
  ItemTitle,
  ItemDescription,
} from './home-solutions.styles';

export const Solutions = (props) => {
  const { title, items, buttonText } = props;

  const widthImageStatic = 90;
  const heightImageStatic = 90;

  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <ContentHead>
            <StyledTitle as="h2">{title}</StyledTitle>
          </ContentHead>
          <Grid
            columns={3}
            gap={0}
            media={{
              md: { columns: 1 },
            }}
          >
            <GridCell>
              <ItemWrapper borderRight>
                <ItemContent>
                  <ItemHead>
                    <ItemImg>
                      <ImageStatic
                        src={SolutionImg1}
                        alt="Two overlapping cards"
                        width={widthImageStatic}
                        height={heightImageStatic}
                      />
                    </ItemImg>
                    <ItemTitle as="h3">{items[0].title}</ItemTitle>
                  </ItemHead>
                  <ItemDescription
                    dangerouslySetInnerHTML={{
                      __html: getTextWithoutParagraph(items[0].description),
                    }}
                  />
                </ItemContent>
                <SellGiftCardsLink to={items[0].buttonlink.link}>
                  <SellGiftCardsLinkText color="blue">{buttonText}</SellGiftCardsLinkText>
                  <SellGiftCardsLinkArrowIcon theme="blue" />
                </SellGiftCardsLink>
              </ItemWrapper>
            </GridCell>
            <GridCell>
              <ItemWrapper borderRight>
                <ItemContent>
                  <ItemHead>
                    <ItemImg>
                      <ImageStatic
                        src={SolutionImg2}
                        alt="Person outline"
                        width={widthImageStatic}
                        height={heightImageStatic}
                      />
                    </ItemImg>
                    <ItemTitle as="h3">{items[1].title}</ItemTitle>
                  </ItemHead>
                  <ItemDescription
                    dangerouslySetInnerHTML={{
                      __html: getTextWithoutParagraph(items[1].description),
                    }}
                  />
                </ItemContent>
                <SellGiftCardsLink to={items[1].buttonlink.link}>
                  <SellGiftCardsLinkText color="purple">{buttonText}</SellGiftCardsLinkText>
                  <SellGiftCardsLinkArrowIcon theme="purple" />
                </SellGiftCardsLink>
              </ItemWrapper>
            </GridCell>
            <GridCell>
              <ItemWrapper>
                <ItemContent>
                  <ItemHead>
                    <ItemImg>
                      <ImageStatic
                        src={SolutionImg3}
                        alt="Retail store logo"
                        width={widthImageStatic}
                        height={heightImageStatic}
                      />
                    </ItemImg>
                    <ItemTitle as="h3">{items[2].title}</ItemTitle>
                  </ItemHead>
                  <ItemDescription
                    dangerouslySetInnerHTML={{
                      __html: getTextWithoutParagraph(items[2].description),
                    }}
                  />
                </ItemContent>
                <SellGiftCardsLink to={items[2].buttonlink.link}>
                  <SellGiftCardsLinkText color="purple-dark">{buttonText}</SellGiftCardsLinkText>
                  <SellGiftCardsLinkArrowIcon theme="purple-dark" />
                </SellGiftCardsLink>
              </ItemWrapper>
            </GridCell>
          </Grid>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};
