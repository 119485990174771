import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export default ({ amount, currency, id }) => {
  let price = `${amount}`;
  if (currency.match(/eur/i)) {
    price = `€${price}`;
  }
  if (currency.match(/usd/i)) {
    price = `$${price}`;
  }
  if (currency.match(/gbp/i)) {
    price = `£${price}`;
  }
  return (
    <AnimatePresence exitBeforeEnter>
      <Price
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.15,
            ease: 'easeOut',
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.3,
            ease: 'easeIn',
          },
        }}
        key={id}
      >
        {price}
      </Price>
    </AnimatePresence>
  );
};
const Price = styled(motion.div)`
  position: absolute;
  top: 30px;
  right: 30px;

  background: linear-gradient(135deg, rgb(238, 247, 252), rgb(228, 237, 242));
  display: inline-block;
  width: auto;
  height: auto;
  padding: 2px 12px;
  color: #555;

  border-radius: 3px;

  opacity: 0.52;
  font-size: 20px;
  color: #2c295d;
  letter-spacing: 0;
`;
