import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import { media, color, fontSize } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Grid as SharedGrid, GridCell } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const Wrapper = styled.section`
  position: relative;
  margin-bottom: 97px;
  ${media.sm} {
    margin-bottom: 1px;
  }
`;

export const Container = styled(SharedContainer)``;

export const Image = styled(GatsbyImage)`
  position: absolute !important;
  bottom: -10px;
  right: 65px;
  ${media.lg} {
    right: 45px;
  }
  ${media.md} {
    position: relative !important;
    right: 0;
  }
  ${media.xs} {
    width: 263px !important;
    height: 349px !important;
  }

  /* parallax */
  transform: translateX(calc((var(--offset, 0)) * -35px));
  ${media.md} {
    transform: none;
  }
`;

export const ImageStatic = styled.img``;

export const ContainerFooter = styled(SharedContainer)`
  position: relative;
  border-radius: 16px;
  background-color: ${color.text.tertiary};
  box-shadow: 14px 56px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  @media (min-width: 1342px) {
    max-width: 1282px;
  }
  ${media.sm} {
    padding-bottom: 43px;
    box-shadow: none;
  }
`;
export const Grid = styled(SharedGrid)``;

export const StyledTitle = styled(Title)`
  font-size: 32px;
  font-weight: normal;
  line-height: 1.41;
  margin-bottom: 73px;
  ${media.sm} {
    font-size: 23px;
    line-height: 1.6;
    margin-bottom: 55px;
  }
  br {
    ${media.lg} {
      display: none;
    }
  }
`;

export const DescriptionWrapper = styled.div``;

export const Description = styled.p`
  font-size: ${fontSize.xs};
  ${media.sm} {
    margin-top: 15px;
  }
`;

export const ContentLeft = styled(GridCell)``;

export const ContentLeftWrapper = styled(GridCell)`
  padding-top: 129px;
  padding-bottom: 143px;
  ${media.sm} {
    padding-top: 23px;
  }
  ${media.xs} {
    padding-bottom: 61px;
  }
`;

export const Bubble = styled.div`
  /* parallax */
  transform: translateY(calc(100px + (var(--offset, 0) - 0.5) * 75px));
  ${media.md} {
    transform: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 519px;
    height: 519px;
    border-radius: 100%;
    background-color: #cceef9;
    ${media.lg} {
      right: 10px;
      width: 450px;
      height: 450px;
    }
    ${media.md} {
      top: -70px;
      right: auto;
      width: 519px;
      height: 519px;
    }
    ${media.xs} {
      top: -38px;
      width: 327px;
      height: 327px;
    }
    animation: 5s ease infinite alternate bubble;

    @keyframes bubble {
      0% {
        transform: scale(1) translateY(3px);
      }
      50% {
        transform: scale(1.025);
      }
    }
  }
`;

export const ContentRight = styled(GridCell)`
  position: relative;
  ${media.md} {
    display: flex;
    justify-content: center;
  }
`;

export const ContentFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 47px 49px 57px 49px;
  ${media.md} {
    flex-direction: column;
  }
  ${media.sm} {
    border-radius: 16px;
    background-color: ${color.text.tertiary};
    box-shadow: 14px 56px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
    padding: 47px 20px 57px 20px;
  }
`;

export const FooterLeft = styled.div`
  display: flex;
  align-items: center;
  ${media.md} {
    flex-direction: column;
    text-align: center;
  }
`;

export const FooterText = styled.div`
  margin-left: 40px;
  ${media.lg} {
    max-width: 440px;
  }
  ${media.md} {
    max-width: 100%;
    margin-left: 0;
    margin-top: 50px;
    margin-bottom: 40px;
  }
`;

export const FooterTitle = styled(Title)`
  font-size: ${fontSize.lg};
  line-height: 1.33;
  margin-bottom: 6px;
  ${media.md} {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 23px;
  }
`;

export const FooterDescription = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: #838791;
  margin: 0;
  ${media.md} {
    font-size: 16px;
    line-height: 1.5;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
