import styled from 'styled-components';

import { media } from 'components/_settings/variables';

export const ScaledContainer = styled.div`
  position: absolute;

  * {
    box-sizing: border-box;
  }

  top: 0;
  right: 163px;

  width: 320px;
  height: 320px;

  ${media.lg} {
    top: 20px;
    right: 70px;
  }

  ${media.md} {
    position: relative;
    left: 0;
    width: 100%;
    height: 240px;
  }
`;

export const CardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  transform: translateY(-30px) scale(0.9);

  ${media.md} {
    position: relative;
    transform: scale(0.75);
  }

  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  perspective: 1000px;
  transform-style: preserve3d;
`;
