import * as React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import { VideoModal } from 'components/blocks/video-modal';

import {
  Wrapper,
  VideoContainer,
  VideoSwiperWrapper,
  VideoItem,
  VideoImage,
  VideoContent,
  VideoPlay,
  VideoTitle,
  VideoText,
} from './home-video.styles';

export const Video = (props) => {
  const { videos } = props;

  const [selectedVideoUrl, setSelectedVideoUrl] = React.useState(null);

  const videoSwiperParams = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <Wrapper>
      <VideoContainer>
        <VideoSwiperWrapper>
          <Slider {...videoSwiperParams}>
            {videos.map((video) => (
              <VideoItem key={video.id}>
                <VideoImage
                  alt={video.acf.image.altText}
                  fluid={{
                    ...video.acf.image.localFile.childImageSharp.fluid,
                    aspectRatio: 1252 / 534,
                  }}
                />
                <VideoContent>
                  <VideoPlay onClick={() => setSelectedVideoUrl(video.acf.url)} />
                  <VideoTitle>{video.acf.title}</VideoTitle>
                  <VideoText>{video.acf.text}</VideoText>
                </VideoContent>
              </VideoItem>
            ))}
          </Slider>
        </VideoSwiperWrapper>
      </VideoContainer>
      {selectedVideoUrl && (
        <VideoModal url={selectedVideoUrl} onClose={() => setSelectedVideoUrl(null)} />
      )}
    </Wrapper>
  );
};
