import styled from 'styled-components';

import { media, color } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Grid as SharedGrid, GridCell } from 'components/shared/grid';
import { Title } from 'components/shared/title';

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 183px;
  ${media.md} {
    margin-bottom: 0;
    padding-bottom: 58px;
  }
`;

export const TriangleBg = styled.img`
  position: absolute;
  top: -420px;
  z-index: -1;
`;

export const Container = styled(SharedContainer)`
  position: relative;
  padding-bottom: 75px;
  ${media.sm} {
    padding-top: 11px;
    padding-bottom: 43px;
  }
`;

export const ImgWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 545px;
  margin-bottom: 102px;
  ${media.sm} {
    min-height: 276px;
    margin-bottom: 53px;
  }
`;

export const ImageStatic = styled.img``;

export const ImgCardWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ImgCardBg1 = styled.img`
  position: absolute;
  top: 0;
  left: 152px;
  ${media.lg} {
    left: 0;
  }
  ${media.sm} {
    display: none;
  }
  /* parallax */
  transform: translateY(calc((var(--offset, 0) - 0.5) * 50px));
  ${media.md} {
    transform: none;
  }
`;

export const ImgCardBg2 = styled.img`
  position: absolute;
  top: 89px;
  right: 31px;
  ${media.lg} {
    right: 0;
  }
  ${media.md} {
    display: none;
  }
  /* parallax */
  transform: translateY(calc((var(--offset, 0) - 0.5) * -50px));
  ${media.md} {
    transform: none;
  }
`;

export const ImgCardBg3 = styled.img`
  position: absolute;
  bottom: -16px;
  right: 108px;
  ${media.lg} {
    bottom: -86px;
    right: 68px;
  }
  ${media.md} {
    bottom: -106px;
    right: 8px;
  }
  ${media.sm} {
    display: none;
  }
  /* parallax */
  transform: translateY(calc((var(--offset, 0) - 0.5) * -50px));
  ${media.md} {
    transform: none;
  }
`;

export const ImgCard1 = styled.img`
  position: absolute;
  top: 0;

  ${media.md} {
    top: 0;

    width: 100%;
    max-width: 601px;
    height: auto;
  }
  ${media.sm} {
    max-width: 327px;
  }
  /* parallax */
  transform: translateY(calc((var(--offset, 0) - 0.5) * 200px));
  ${media.md} {
    transform: none;
  }
`;
export const ImgCard2 = styled.img`
  position: absolute;
  top: 111px;

  ${media.md} {
    top: 125px;

    width: 100%;
    max-width: 620px;
    height: auto;
  }
  ${media.sm} {
    top: 55px;
    max-width: 320px;
  }
  /* parallax */
  transform: translateY(calc((var(--offset, 0) - 0.5) * 100px));
  ${media.md} {
    transform: none;
  }
`;
export const ImgCard3 = styled.img`
  position: absolute;
  bottom: 4px;

  ${media.md} {
    width: 100%;
    max-width: 528px;
    height: auto;
  }
  ${media.sm} {
    bottom: 3px;
    max-width: 284px;
  }
  /* parallax */
  transform: translateY(calc((var(--offset, 0) - 0.5) * -50px));
  ${media.md} {
    transform: none;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 42px;
  line-height: 1.29;
  text-align: center;
  margin: 0 0 18px;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.5;
    white-space: initial;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 57px;
  ${media.sm} {
    margin-bottom: 8px;
  }
`;

export const GridItem = styled(GridCell)``;

export const Grid = styled(SharedGrid)`
  ${GridItem} {
    ${media.sm} {
      margin-bottom: 40px;
    }
    ${media.xs} {
      margin-bottom: 0;
    }
    &:nth-child(1) {
      ${media.xs} {
        margin-bottom: 24px;
      }
    }
    &:nth-child(2) {
      ${media.xs} {
        margin-bottom: 42px;
      }
    }
    &:nth-child(3) {
      ${media.xs} {
        margin-bottom: 43px;
      }
    }
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
`;

export const ItemImg = styled.div`
  margin-right: 32px;
  ${media.md} {
    margin-right: 22px;
  }
  svg {
    ${media.md} {
      width: 59px;
      margin-top: -20px;
    }
  }
`;

export const ItemContent = styled.div``;

export const ItemTitle = styled(Title)`
  font-size: 20px;
  line-height: normal;
  margin-bottom: 9px;
  ${media.sm} {
    font-size: 18px;
    margin-bottom: 18px;
  }
`;

export const ItemDescription = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: ${color.text.secondary};
  margin: 0;
  white-space: pre-wrap;
  ${media.lg} {
    white-space: initial;
  }
  ${media.sm} {
    font-size: 16px;
    line-height: 1.5;
    white-space: initial;
  }
`;
