import usePrevious from '@rooks/use-previous';
import { useEffect, useState } from 'react';

export const useTyping = (word, lineIndex) => {
  const [typing, setValue] = useState('');
  const [done, setDone] = useState(true);
  const [speed, setSpeed] = useState(lineIndex * 100);
  const previousWord = usePrevious(word);

  useEffect(() => {
    const dest = [...typing];
    const src = [...word];

    const update = () => {
      const index = dest.length - 1;
      const prevNotMatching = dest.find(
        (value, index) => !dest[index] || dest[index] !== src[index]
      );
      if (index + 1 > src.length || prevNotMatching) {
        dest.pop();
        if (done) {
          setSpeed(500 * lineIndex);
        } else {
          setSpeed(50);
        }
      } else {
        if (!done) {
          // setSpeed(1000 * lineIndex)
          setSpeed(10);
        } else {
          setSpeed(100 * lineIndex);
        }

        setDone(false);

        dest.push(src[index + 1]);
      }
      setValue(dest.join(''));
    };

    if (typing === word) {
      // setDone(true)
    } else {
      // setDone(false)
    }

    const timeout = setTimeout(update, speed);

    return () => {
      clearTimeout(timeout);
    };
  }, [word, previousWord, typing, done]);

  return { typing };
};
