import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Collection } from 'components/blocks/collection-gift-cards/';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Company } from 'components/pages/home/company';
import { Intro } from 'components/pages/home/intro';
import { Platform } from 'components/pages/home/platform';
import { Solutions } from 'components/pages/home/solutions';
import { Video } from 'components/pages/home/video';
import { Banner } from 'components/shared/banner/banner';
import { Partner } from 'components/shared/partner';
import { extractItems } from 'utils/extract-items';

import { buildPageMetadata } from '../utils/data-builders';

const Home = (props) => {
  const {
    data: {
      wpPage,
      wpPage: { acf },
    },
  } = props;

  const solutionItems = extractItems(acf, 'section3Item');
  const platformItems = extractItems(acf, 'section4Item');
  const isShowBanner = acf.homeBanner.show;

  return (
    <>
      {isShowBanner && (
        <Banner
          id="home"
          text={acf.homeBanner.text}
          linkText={acf.homeBanner.linkText}
          linkUrl={acf.homeBanner.linkUrl}
        />
      )}
      <Layout pageMetadata={buildPageMetadata(wpPage)}>
        <Header />
        <Intro
          title={acf.section1Title}
          description={acf.section1Description}
          topLabel={acf.section1TopLabel}
          topText={acf.section1TopText}
          buttonText={acf.section1ButtonText}
          buttonLink={acf.section1ButtonLink}
          buttonText2={acf.section1ButtonText2}
          buttonLink2={acf.section1ButtonLink2}
          bottomText={acf.section1BottomText}
          partnersLogos={acf.section1Partners}
        />
        <Solutions
          title={acf.section3Title}
          items={solutionItems}
          buttonText={acf.section3ButtonText}
        />
        <Platform title={acf.section4Title} items={platformItems} />
        <Collection
          title={acf.section5Title}
          description={acf.section5Description}
          bottomTitle={acf.section5BottomTitle}
          bottomDescription={acf.section5BottomDescription}
          buttonText={acf.section5ButtonText}
          buttonLink={acf.section5ButtonLink}
        />

        <Company title={acf.section6Title} items={acf.section6Company} />
        <Video videos={acf.section7Videos} />
        <Partner />
        <Footer theme="light" />
      </Layout>
    </>
  );
};

export default Home;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      acf {
        homeBanner {
          show
          text
          linkText
          linkUrl
        }
        section1Title
        section1Description
        section1ButtonText
        section1ButtonLink
        section1ButtonText2
        section1ButtonLink2
        section1Partners {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section1TopLabel
        section1TopText
        section1BottomText
        section2Title
        section2Description
        section2FormTitle
        section2FormDescription
        section2FormDescriptionSuccess
        section3Title
        section3ButtonText
        section3Item1Title
        section3Item1Description
        section3Item1ButtonLink {
          ... on WpPage {
            link
          }
        }
        section3Item2Title
        section3Item2Description
        section3Item2ButtonLink {
          ... on WpPage {
            link
          }
        }
        section3Item3Title
        section3Item3Description
        section3Item3ButtonLink {
          ... on WpPage {
            link
          }
        }
        section4Title
        section4Item1Title
        section4Item1Description
        section4Item2Title
        section4Item2Description
        section4Item3Title
        section4Item3Description
        section4Item4Title
        section4Item4Description
        section5Title
        section5Description
        section5BottomTitle
        section5BottomDescription
        section5ButtonText
        section5ButtonLink
        section6Title
        section6Company {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Videos {
          ... on WpCustomersPageVideo {
            id
            acf {
              title
              text
              url
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
