import { motion } from 'framer-motion';
import styled from 'styled-components';

const codeHeight = 180;

export const headerAnimations = {
  hidden: { height: 210 - 60 },
  card: { height: 210 - 60 },
  logos: { height: 210 - 60 },
  code: { height: 350 - codeHeight },
  slider: { height: 210 - 90 },
  done: { height: 210 - 90 },
};

export const footerAnimations = {
  hidden: {
    height: 60,
  },
  card: {
    height: 60,
  },
  logos: {
    height: 60,
  },
  code: {
    height: codeHeight,
  },
  slider: {
    height: 90,
  },
  done: {
    height: 90,
  },
};

export const CardHeader = styled(motion.div)`
  flex: 1;
  position: relative;
  overflow: hidden;
  width: 100%;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const CardFooter = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 17px 25px;

  h2 {
    font-size: 15px;
    color: #2c295d;
    letter-spacing: 1.45px;

    margin: 0;
    margin-bottom: 12px;
    color: #888;

    text-transform: uppercase;
  }
`;
