import useRaf from '@rooks/use-raf';
import React, { useState } from 'react';
import styled from 'styled-components';

export const GradientPlane = (props) => {
  const [increment, setIncrement] = useState(0);
  useRaf(() => {
    setIncrement(increment + 1);
  }, true);

  const t = Math.floor(increment / 3) * 2;
  const a = (Math.sin(t * 0.01) * 0.2 + Math.cos(t * 0.001)) * 120;
  const b1 = Math.abs(Math.cos(t * 0.021)) * 0.5 + 0.5;
  const b2 = Math.abs(Math.sin(t * 0.015)) * 0.5 + 0.5;
  const b3 = Math.abs(Math.cos(t * 0.007)) * 0.5 + 0.5;

  const style = {
    backgroundImage: `linear-gradient(
    ${Math.floor(a * -5)}deg, 
    rgba(${Math.floor(b2 * 255)},${Math.floor(b1 * 0)},${Math.floor(b1 * 127)}, ${props.opacity}),
    rgba(${Math.floor(b1 * 127)},${Math.floor(b2 * 255)},${Math.floor(b3 * 255)}, ${props.opacity})
    )`,
  };

  return <GradientColor style={style} />;
};

const GradientColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
`;
