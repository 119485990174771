import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export default ({ src, scale }) => (
  <AnimatePresence exitBeforeEnter>
    <Logo
      scale={scale}
      initial={{ y: ' 100px', opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.15,
          ease: 'easeOut',
        },
      }}
      exit={{
        y: '-100%',
        opacity: 0,
        transition: {
          duration: 0.3,
          ease: 'easeIn',
        },
      }}
      src={src}
      key={src}
      alt="Brand Logo"
    />
  </AnimatePresence>
);

const Logo = styled(motion.img)`
  box-sizing: border-box;
  position: relative;
  width: ${({ scale }) => `${scale * 100}%`};
  height: 120px;
  max-height: 120px;
  padding: 30px;
  object-fit: contain;
  object-position: top left;
`;
