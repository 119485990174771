import * as React from 'react';

import { getFluidImage } from 'utils';

import {
  Wrapper,
  Container,
  CompanyWrapper,
  ContentHead,
  StyledTitle,
  ItemImg,
} from './home-company.styles';

export const Company = (props) => {
  const { title, items } = props;

  return (
    <Wrapper>
      <Container>
        <ContentHead>
          <StyledTitle as="h2">{title}</StyledTitle>
        </ContentHead>
        <CompanyWrapper>
          {items.map((item) => (
            <ItemImg
              key={item.id}
              style={{
                width: getFluidImage(item.localFile).presentationWidth / 2,
                height: getFluidImage(item.localFile).presentationHeight / 2,
              }}
              fluid={getFluidImage(item.localFile)}
              alt={item.altText}
            />
          ))}
        </CompanyWrapper>
      </Container>
    </Wrapper>
  );
};
