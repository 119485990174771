import GatsbyImage from 'gatsby-image';
import * as React from 'react';
import Slider from 'react-slick';

import { getFluidImage } from 'utils';

import {
  Wrapper,
  Container,
  LogoWrapper,
  ContentWrapper,
  SliderWrapper,
} from './home-intro-logos-block.styles';

export const LogosBlock = ({ className, items }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: false,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const logoItems = items.map(({ id, altText, localFile }) => {
    const fluid = getFluidImage(localFile);
    return (
      <LogoWrapper key={id}>
        <GatsbyImage
          style={{
            width: fluid.presentationWidth / 2,
            height: fluid.presentationHeight / 2,
          }}
          fluid={fluid}
          alt={altText}
          loading="eager"
          key={id}
        />
      </LogoWrapper>
    );
  });

  return (
    <Wrapper className={className}>
      <Container>
        <ContentWrapper>{logoItems}</ContentWrapper>
      </Container>
      <SliderWrapper>
        <Slider {...settings}>{logoItems}</Slider>
      </SliderWrapper>
    </Wrapper>
  );
};
