import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { PartnerView } from './partner.view';

export const Partner = () => {
  const {
    wpSharedBlock: {
      acf: { quote, caption, bannerTitle, bannerDescription, bannerButtonText, bannerButtonLink },
    },
    imagePerson: {
      childImageSharp: { fixed: imagePersonFixed },
    },
  } = useStaticQuery(graphql`
    query {
      imagePerson: file(relativePath: { eq: "pages/home/drive/vodafone-testimonial@2x.png" }) {
        childImageSharp {
          fixed(width: 418, height: 553) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      wpSharedBlock(slug: { eq: "partner" }) {
        acf {
          quote
          caption
          bannerTitle
          bannerDescription
          bannerButtonText
          bannerButtonLink {
            url
          }
        }
      }
    }
  `);

  return (
    <PartnerView
      quote={quote}
      caption={caption}
      bannerTitle={bannerTitle}
      bannerDescription={bannerDescription}
      bannerButtonText={bannerButtonText}
      bannerButtonLink={bannerButtonLink}
      imagePerson={imagePersonFixed}
    />
  );
};
