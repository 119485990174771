import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const Wrapper = styled.section`
  position: relative;
  margin-bottom: 23px;
  padding-bottom: 58px;
  ${media.sm} {
    padding-bottom: 24px;
  }
`;

export const Container = styled(SharedContainer)``;

export const StyledTitle = styled(Title)`
  font-size: 52px;
  line-height: 1.42;
  text-align: center;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.25;
    white-space: initial;
  }
`;

export const ContentHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 55px;
  ${media.sm} {
    margin-bottom: 33px;
  }
`;

export const CompanyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 125px 0 129px;
  ${media.lg} {
    padding: 0 45px 0 45px;
  }
  ${media.md} {
    padding: 0;
  }
  ${media.sm} {
    padding: 0 15px 0 15px;
  }
`;

export const ItemImg = styled(GatsbyImage)`
  margin: 27px 29px;
  ${media.md} {
    margin: 27px 50px;
  }
  ${media.md} {
    height: 40px !important;
    width: 90px !important;
    margin: 20px;
  }
  ${media.sm} {
    height: 40px !important;
    width: 70px !important;
    margin: 10px;
  }
  ${media.xs} {
    height: 30px !important;
    width: 50px !important;
    margin: 10px;
  }

  img {
    ${media.md} {
      object-fit: contain !important;
    }
  }
`;
