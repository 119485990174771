import React from 'react';
import styled from 'styled-components';

import { useTyping } from './useTyping';

export default (props) => {
  const text = `currency.send({
    brand: "${props.brand.name}",
    value: ${props.brand.amount},
    currency: "${props.brand.currency}",
    email: "${props.brand.email}"
  });`;

  const lines = text.split(/\n/).map((line, index) => {
    const { typing } = useTyping(line, index, () => {});
    return typing;
  });

  let processedText = lines.join('\n');

  processedText = processedText
    .replace(/('.*')/gi, '<span class="string">$1</span>')
    .replace(/(".*")/gi, '<span class="string">$1</span>')
    .replace(/(\d+)/gi, '<span class="number">$1</span>')
    .replace(/(const|let)/gi, '<span class="keyword">$1</span>')
    .replace(/([a-z]+)\./gi, '<span class="keyword">$1</span>.')
    .replace(/([a-z]+):/gi, '<span class="property">$1</span>:')
    .replace(/(await)/gi, '<span class="keyword2">$1</span>');

  return <CodeStyle dangerouslySetInnerHTML={{ __html: processedText }} />;
};

const CodeStyle = styled.div`
  white-space: pre-wrap;
  font-size: 9px;
  // line-height: 1.2em;
  color: #555;
  // padding: 15px;
  margin: 0;

  font-size: 16px;
  color: #919ac7;
  letter-spacing: 0.34px;

  .property {
    // color: #c09;
  }
  .keyword {
    // font-weight: bold;
    // color: #955;
  }
  .keyword2 {
    // font-weight: bold;
  }
  .string,
  .number {
    color: #c7919a;
    /* font-weight: bold; */
    .keyword {
      color: inherit;
      font-weight: inherit;
    }
  }
`;
