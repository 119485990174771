import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import { color, media, fontSize } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';

export const Wrapper = styled.section`
  position: relative;
  margin-bottom: 8px;
  padding-bottom: 20px;
`;

export const VideoContainer = styled(SharedContainer)`
  margin-bottom: 80px;
  @media (min-width: 1306px) {
    max-width: 1246px;
  }
  ${media.md} {
    margin-bottom: 0;
  }
`;

export const VideoItem = styled.div`
  display: block !important;
`;

export const VideoSwiperWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  z-index: 3;
  .slick-slide {
    position: relative;
    &:active {
      outline: none;
    }
    ${media.md} {
      height: 350px;
    }
    ${media.sm} {
      height: 202px;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 28px;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    text-align: center;
    ${media.md} {
      bottom: 3px;
    }
    li {
      display: inline-block;
      padding: 0 3px;
      button {
        border: none;
        outline: none;
        background-color: rgba(255, 255, 255, 0.2);
        text-indent: -9999px;
        overflow: hidden;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        display: block;
        -webkit-appearance: none !important;
        padding: 0;
      }
    }
    li.slick-active {
      button {
        background: #fff;
      }
    }
  }
`;

export const VideoImage = styled(GatsbyImage)`
  ${media.md} {
    height: 350px;
  }
  ${media.sm} {
    height: 202px;
  }
`;

export const VideoContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(244deg, rgba(15, 12, 59, 0.23), #2c295d);
  color: #fff;
  overflow: hidden;
  text-align: center;
  padding: 135px 40px 0;

  ${media.lg} {
    padding-top: 60px;
  }

  ${media.sm} {
    padding: 0 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`;

export const VideoPlay = styled.div`
  width: 104px;
  height: 104px;
  border-radius: 50%;
  background: ${color.text.tertiary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 82px;
  flex-shrink: 0;
  cursor: pointer;
  ${media.lg} {
    margin: 0 auto 40px;
  }
  ${media.md} {
    width: 60px;
    height: 60px;
    margin-bottom: 34px;
  }
  ${media.sm} {
    margin: 0 0 22px;
    width: 57px;
    height: 57px;
  }
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #e2fbff;
    ${media.md} {
      width: 26px;
      height: 26px;
    }
  }
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11px 0 11px 15px;
    border-color: transparent transparent transparent #00c1de;
    margin-left: 5px;
    z-index: 1;
    ${media.md} {
      border-width: 5px 0 5px 8px;
      margin-left: 2px;
    }
  }
`;

export const VideoTitle = styled.div`
  font-size: 42px;
  font-weight: 500;
  margin-bottom: 17px;
  ${media.md} {
    font-size: 24px;
  }
  ${media.sm} {
    font-size: 16px;
    margin-bottom: 9px;
  }
`;

export const VideoText = styled.div`
  font-size: ${fontSize.sm};
  line-height: 1.75;

  ${media.sm} {
    display: none;
  }
`;
