import * as React from 'react';

import TriangleBackgroundSvgUrl from 'assets/images/pages/home/platform/background-triangle-platform.url.svg';
import PlatformImgSvgUrl2 from 'assets/images/pages/home/platform/best-discounts.url.svg';
import ImageCardSvgUrl3 from 'assets/images/pages/home/platform/bottom-copy.url.svg';
import ImageCardSvgUrl2 from 'assets/images/pages/home/platform/code-copy.url.svg';
import ImageCardBgSvgUrl2 from 'assets/images/pages/home/platform/element-1.url.svg';
import ImageCardBgSvgUrl3 from 'assets/images/pages/home/platform/element-2.url.svg';
import ImageCardBgSvgUrl1 from 'assets/images/pages/home/platform/element-3.url.svg';
import PlatformImgSvgUrl3 from 'assets/images/pages/home/platform/integrate-seamlessly.url.svg';
import PlatformImgSvgUrl4 from 'assets/images/pages/home/platform/multi-currency.url.svg';
import PlatformImgSvgUrl1 from 'assets/images/pages/home/platform/thousands-brands.url.svg';
import ImageCardSvgUrl1 from 'assets/images/pages/home/platform/top.url.svg';
import { getTextWithoutParagraph } from 'utils';
import { useParallax } from 'utils/use-parallax';

import {
  Wrapper,
  ImgWrapper,
  ImageStatic,
  ImgCardWrapper,
  ImgCardBg1,
  ImgCardBg2,
  ImgCardBg3,
  ImgCard1,
  ImgCard2,
  ImgCard3,
  Container,
  ContentWrapper,
  ContentHead,
  TriangleBg,
  Grid,
  GridItem,
  ItemWrapper,
  ItemImg,
  ItemContent,
  ItemTitle,
  ItemDescription,
  StyledTitle,
} from './home-platform.styles';

export const Platform = (props) => {
  const { title, items } = props;
  const { ref, offset } = useParallax();

  const widthImageStatic = 97;
  const heightImageStatic = 97;
  return (
    <Wrapper ref={ref} style={{ '--offset': offset }}>
      <TriangleBg src={TriangleBackgroundSvgUrl} alt="" />
      <Container>
        <ContentWrapper>
          <ContentHead>
            <StyledTitle as="h2">{title}</StyledTitle>
          </ContentHead>
          <ImgWrapper>
            <ImgCardBg1 src={ImageCardBgSvgUrl1} alt="" />
            <ImgCardBg2 src={ImageCardBgSvgUrl2} alt="" />
            <ImgCardBg3 src={ImageCardBgSvgUrl3} alt="" />
            <ImgCardWrapper>
              <ImgCard3 src={ImageCardSvgUrl3} alt="" />
              <ImgCard2 src={ImageCardSvgUrl2} alt="API pseudo code example" />
              <ImgCard1 src={ImageCardSvgUrl1} alt="Gift card worth 50 dollars" />
            </ImgCardWrapper>
          </ImgWrapper>

          <Grid
            columns={2}
            gap={105}
            media={{
              lg: { gap: 50 },
              md: { gap: 50 },
              sm: { columns: 1, gap: 0 },
            }}
          >
            <GridItem>
              <ItemWrapper>
                <ItemImg>
                  <ImageStatic
                    src={PlatformImgSvgUrl1}
                    alt=""
                    width={widthImageStatic}
                    height={heightImageStatic}
                  />
                </ItemImg>
                <ItemContent>
                  <ItemTitle as="h3">{items[0].title}</ItemTitle>
                  <ItemDescription
                    dangerouslySetInnerHTML={{
                      __html: getTextWithoutParagraph(items[0].description),
                    }}
                  />
                </ItemContent>
              </ItemWrapper>
            </GridItem>
            <GridItem>
              <ItemWrapper>
                <ItemImg>
                  <ImageStatic
                    src={PlatformImgSvgUrl2}
                    alt=""
                    width={widthImageStatic}
                    height={heightImageStatic}
                  />
                </ItemImg>
                <ItemContent>
                  <ItemTitle as="h3">{items[1].title}</ItemTitle>
                  <ItemDescription
                    dangerouslySetInnerHTML={{
                      __html: getTextWithoutParagraph(items[1].description),
                    }}
                  />
                </ItemContent>
              </ItemWrapper>
            </GridItem>
            <GridItem>
              <ItemWrapper>
                <ItemImg>
                  <ImageStatic
                    src={PlatformImgSvgUrl3}
                    alt=""
                    width={widthImageStatic}
                    height={heightImageStatic}
                  />
                </ItemImg>
                <ItemContent>
                  <ItemTitle as="h3">{items[2].title}</ItemTitle>
                  <ItemDescription
                    dangerouslySetInnerHTML={{
                      __html: getTextWithoutParagraph(items[2].description),
                    }}
                  />
                </ItemContent>
              </ItemWrapper>
            </GridItem>
            <GridItem>
              <ItemWrapper>
                <ItemImg>
                  <ImageStatic
                    src={PlatformImgSvgUrl4}
                    alt=""
                    width={widthImageStatic}
                    height={heightImageStatic}
                  />
                </ItemImg>
                <ItemContent>
                  <ItemTitle as="h3">{items[3].title}</ItemTitle>
                  <ItemDescription
                    dangerouslySetInnerHTML={{
                      __html: getTextWithoutParagraph(items[3].description),
                    }}
                  />
                </ItemContent>
              </ItemWrapper>
            </GridItem>
          </Grid>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};
