import styled from 'styled-components';

import CardBorderImgSvg from 'assets/images/pages/home/intro/border-left.svg';
import CardImgSvg from 'assets/images/pages/home/intro/card-home.svg';
import { fontSize, color, media } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const CardImgWrapper = styled.div`
  z-index: 1;
  ${media.md} {
    margin-bottom: 53px;
  }
`;

export const CardImg = styled(CardImgSvg)`
  position: absolute;
  top: 28px;
  right: 163px;

  ${media.lg} {
    right: 73px;
  }

  ${media.md} {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
`;

export const CardBorderImg = styled(CardBorderImgSvg)`
  position: absolute;
  top: -13px;
  right: 365px;

  ${media.lg} {
    right: 265px;
  }

  ${media.md} {
    display: none;
  }
`;

export const Triangle1 = styled.img`
  position: absolute;
  top: 0;
  right: -236px;
  z-index: -1;

  ${media.lg} {
    right: -575px;
  }

  ${media.md} {
    display: none;
  }
`;

export const Triangle2 = styled.img`
  position: absolute;
  top: -119px;
  right: -236px;
  width: 868px;

  ${media.lg} {
    right: -326px;
  }

  ${media.sm} {
    top: -51px;
    right: -190px;
    width: 548px;
  }
`;

export const TriangleImgWrapper = styled.div`
  z-index: -1;
`;

export const ImageStatic = styled.img``;

export const BottomText = styled.p`
  font-size: ${fontSize.xs};
  line-height: 2;
  color: ${color.text.secondary};
  margin: 6px 0 0;
`;

export const BottomTextWrapper = styled.div`
  position: absolute;
  bottom: -48px;
  right: -45px;

  ${media.xl} {
    bottom: -78px;
    width: 390px;
    right: 128px;
  }

  ${media.lg} {
    bottom: -48px;
    right: 23px;
  }

  ${media.md} {
    position: relative;
    bottom: 0;
    right: 0;
    margin-bottom: 40px;
    width: 100%;
  }
`;

export const WrapperButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 475px;
  column-gap: 16px;

  ${media.xs} {
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
`;

export const StyledButton = styled(Button)`
  & > span {
    text-align: center;
    width: 100%;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  font-weight: normal;
  line-height: 1.56;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 43px;

  ${media.sm} {
    margin-bottom: 36px;
    br {
      display: none;
    }
  }
`;

export const StyledTitle = styled(Title)`
  font-size: 62px;
  font-weight: normal;
  line-height: 1.23;
  margin-bottom: 27px;

  ${media.sm} {
    font-size: 42px;
    line-height: 1.33;
    margin-bottom: 18px;
  }
`;

export const DescriptionHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  visibility: hidden;

  ${media.xs} {
    display: none;
  }
`;

export const DescriptionHeadLabel = styled.span`
  font-size: ${fontSize.sm};
  font-weight: 500;
  line-height: normal;
  color: #00c1de;
  padding: 3px 10px 5px;
  border-radius: 2px;
  background-color: #defbff;
  margin-right: 16px;
`;

export const DescriptionHeadText = styled.p`
  font-size: ${fontSize.sm};
  font-weight: normal;
  line-height: normal;
  margin: 0;
`;

export const Content = styled.div`
  margin-bottom: 128px;

  ${media.lg} {
    max-width: 410px;
  }

  ${media.md} {
    margin-bottom: 35px;
    max-width: 100%;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-top: 165px;
  overflow: hidden;

  ${media.md} {
    padding-top: 120px;
  }
`;
