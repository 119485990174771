import React, { useState, useEffect } from 'react';

import { CardContainer, ScaledContainer } from './card-animation.styles';
import Card from './card/Card';
import brands from './data/brands.json';
import useMouseAngle from './hooks/use-mouse-angle';

const cardTitle = 'Incentive';

const animations = [
  { id: 'hidden', duration: 0 },
  { id: 'card', duration: 0 },
  { id: 'logos', duration: 50 },
  { id: 'code', duration: 1750 * 3 },
  { id: 'slider', duration: 1250 },
  { id: 'done', duration: 1750 },
];

export const CardAnimation = () => {
  const defaultAnimation = 'hidden';
  const [autoplay, setAutoplay] = useState(true);
  const { screenCoords } = useMouseAngle();
  const [animate, setAnimate] = useState(defaultAnimation);

  const [brand, setBrand] = useState(brands[0]);

  const animateLogos = !animate.match(/hidden|card|logos|slider|done/);

  useEffect(() => {
    if (!animateLogos) {
      return;
    }
    const interval = setInterval(() => {
      const index = (brands.indexOf(brand) + 1) % brands.length;
      setBrand(brands[index]);
    }, 1750);
    return () => {
      clearInterval(interval);
    };
  }, [brand, animateLogos, animate]);

  useEffect(() => {
    if (!autoplay) {
      return;
    }

    const current = animations.find((a) => a.id === animate);
    const index = (current && animations.indexOf(current)) || 0;

    if (current) {
      const timeout = setTimeout(() => {
        const next = (index + 1) % animations.length || 1;

        setAnimate(animations[next].id);
      }, current.duration);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [animations, animate, autoplay]);

  /**
   * Debug buttons 0-6
   */
  useEffect(() => {
    if (typeof window === 'undefined') {
      return null;
    }
    const onKeyUp = (e) => {
      const n = Number(e.key);
      const id = n && animations[n - 1] && animations[n - 1].id;
      if (id) {
        setAnimate(id);
        setAutoplay(false);
      }
    };
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [animations]);

  return (
    <ScaledContainer>
      <CardContainer>
        <Card coords={screenCoords} animate={animate} brand={brand} cardTitle={cardTitle} />
      </CardContainer>
    </ScaledContainer>
  );
};
