import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import GradientBackground from './background/GradientBackground';
import Card3d from './Card3d';
import CardContent from './content/CardContent';

const cardAnimationVariants = {
  hidden: { width: 320, height: 210 },
  card: { width: 320, height: 210 },
  logos: { width: 320, height: 210 },
  code: { width: 320, height: 320 },
  slider: { width: 320, height: 210 },
  done: { width: 320, height: 210 },
  out: { width: 320, height: 210 },
};

export const cardTransition = { type: 'spring', stiffness: 140, damping: 100 };

export default (props) => {
  const [x] = props.coords;
  const { brand, coords, animate, cardTitle } = props;
  const hidden = animate.match('hidden');

  return (
    <div>
      {/*
        Gradient plane
      */}
      <GradientBackground coords={coords} animate={animate} />

      <Card3d coords={coords} z={50} fadeIn={hidden ? 0 : 1}>
        <Card
          animate={animate}
          initial="hidden"
          transition={cardTransition}
          variants={cardAnimationVariants}
          z={20}
          style={{
            boxShadow: `${x * 10}px 30px 40px rgba(0, 0, 0, 0.2)`,
            background: 'linear-gradient(45deg, rgba(255, 255, 255, 1), rgba(246, 249, 255, 1))',
          }}
        >
          <CardContent animate={animate} brand={brand} cardTitle={cardTitle} />
        </Card>
      </Card3d>
    </div>
  );
};

const Card = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform-style: preserve-3d;
  transform-origin: 50% 0;
  transform: translate(-50%, -50%);
  border-radius: 10px;
`;
