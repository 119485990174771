import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export default function Card3d(props) {
  const [x, y] = props.coords;
  const factor = props.factor || 1;
  const { fadeIn } = props;
  const out = 1 - fadeIn;

  return (
    <Card3dStyle
      transition={{ type: 'spring', stiffness: 60 }}
      initial={{
        opacity: 0,
        y: 100,
      }}
      animate={{
        opacity: fadeIn,
        y: out * 100,
        z: props.z,
        rotateZ: out * 0,
        rotateY: x * 20 * factor + out * -30,
        rotateX: y * 20 * factor + 20 * out,
      }}
    >
      {props.children}
    </Card3dStyle>
  );
}

const Card3dStyle = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform-style: preserve-3d;
`;
