import { Link } from 'gatsby';
import * as React from 'react';

import TriangleSvgUrl2 from 'assets/images/pages/home/intro/home.url.svg';
import TriangleSvgUrl1 from 'assets/images/pages/home/intro/triangle-gray.url.svg';
import BottomLogoSvgUrl from 'assets/images/pages/home/intro/vodafone-color.url.svg';
import { getTextWithoutParagraph } from 'utils';

import { CardAnimation } from './card-animation';
import {
  Wrapper,
  Inner,
  Content,
  DescriptionHead,
  DescriptionHeadLabel,
  DescriptionHeadText,
  StyledTitle,
  Description,
  WrapperButtons,
  StyledButton,
  BottomTextWrapper,
  BottomText,
  CardImgWrapper,
  ImageStatic,
  TriangleImgWrapper,
  Triangle1,
  Triangle2,
} from './home-intro.styles';
import { LogosBlock } from './logos-block';

export const Intro = (props) => {
  const {
    topLabel,
    topText,
    title,
    description,
    buttonText,
    buttonLink,
    buttonText2,
    buttonLink2,
    bottomText,
    partnersLogos,
  } = props;

  const [isBrowser, setIsBrowser] = React.useState(false);
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsBrowser(true);
    }
  }, []);

  return (
    <Wrapper>
      <Inner>
        <Content>
          <DescriptionHead>
            <DescriptionHeadLabel>{topLabel}</DescriptionHeadLabel>
            <DescriptionHeadText>{topText}</DescriptionHeadText>
          </DescriptionHead>
          <StyledTitle
            size="xxl"
            dangerouslySetInnerHTML={{
              __html: getTextWithoutParagraph(title),
            }}
          />
          <Description
            dangerouslySetInnerHTML={{
              __html: getTextWithoutParagraph(description),
            }}
          />
          <WrapperButtons>
            <StyledButton component={Link} theme="fill" to={buttonLink} arrow={false}>
              {buttonText}
            </StyledButton>
            <StyledButton component={Link} theme="bordered" to={buttonLink2} arrow={false}>
              {buttonText2}
            </StyledButton>
          </WrapperButtons>
        </Content>
        <CardImgWrapper>
          {isBrowser && <CardAnimation />}
          {/* <CardBorderImg /> */}
        </CardImgWrapper>
        <BottomTextWrapper>
          <ImageStatic src={BottomLogoSvgUrl} alt="Vodafone" width={114} height={28} />
          <BottomText>{bottomText}</BottomText>
        </BottomTextWrapper>
      </Inner>

      <TriangleImgWrapper>
        <Triangle1 src={TriangleSvgUrl1} alt="" />
        <Triangle2 src={TriangleSvgUrl2} alt="" />
      </TriangleImgWrapper>
      <LogosBlock items={partnersLogos} />
    </Wrapper>
  );
};
